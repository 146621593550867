// PictureEditor.js
import React, { useRef, useState } from 'react';
import axios from 'axios';
import api from '../../../../utils/api';
import { CircularProgress } from '@mui/material';
import { TbEdit } from "react-icons/tb";

async function uploadImage(file, isOutProfileUpload, setUser, onUploadSuccess, setLoading) {
    try {
        setLoading(true);
        const fileExtension = file.name.split('.').pop();
        const { data } = await api.post('/upload/signedUrl', {
            fileExtension: fileExtension,
        });

        await axios.put(data.signedUrl.url, file, {
            headers: {
                'Content-Type': file.type,
            },
        });

        let reqData = {
            [isOutProfileUpload ? 'outProfileLogo' : 'profilePicture']: 
            `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/` + data.signedUrl.key,
        };

        const res = await api.put('/user/info', reqData);
        localStorage.setItem('@SKANZ/user', JSON.stringify(res.data.user));
        setUser(res.data.user);
        onUploadSuccess(res.data.user);
    } catch (error) {
        console.log('Error uploading image:', error);
    } finally {
        setLoading(false);
    }
}

const PictureEditor = ({ type, setUser, picture, onUploadSuccess }) => {
    const inputFile = useRef(null);
    const [loading, setLoading] = useState(false);

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const isOutProfile = type === 'out-profile';
        await uploadImage(file, isOutProfile, setUser, onUploadSuccess, setLoading);
    };

    return (
        <>
            <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={handleImageUpload}
                accept="image/*"
            />
            <div onClick={() => inputFile.current.click()} id="editPhoto" className="picture-editor-container">
                {loading ? (
                    <div style={{ backgroundColor: 'black' }} className="col-4 profile-spinner rounded-circle profile-picture">
                        <CircularProgress color="inherit" className="mt-4 ml-4" />
                    </div>
                ) : (
                    <img
                        alt="Profile"
                        src={picture ?? 'assets/img/brand/logo.png'}
                        className="col-4 rounded-circle profile-picture"
                    />
                )}
                
                <TbEdit className="profile-edit" />
            </div>
        </>
    );
};

export default PictureEditor;

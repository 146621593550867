import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import '../../../../styles/sass/pages/share.scss';
import api from '../../../../utils/api';
import TopNav from '../mobile/topNav';
import { useHistory } from 'react-router-dom';
import { BiShare } from "react-icons/bi";
import QrModal from '../mobile/QrModal';

const MobileShare = () => {
  const [tattoos, setTattoos] = useState([]);
  const user = JSON.parse(localStorage.getItem('@SKANZ/user'));
  const thisUrl = window.location.origin;
  const history = useHistory();
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const [selectedTattoo, setSelectedTattoo] = useState(null);

  useEffect(() => {
    // Fetch user's tattoos from the API
    const fetchTattoos = async () => {
      try {
        const response = await api.get(`/user/tattoos`);
        setTattoos(response.data.tattoos);
      } catch (error) {
        console.error('Error fetching tattoos:', error);
      }
    };

    fetchTattoos();
  }, [user.userslug]);

  const showQrModal = (tattoo) => {
    setSelectedTattoo(tattoo);
    setQrModalVisible(true);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className="share">
      <TopNav title="Share" goBack={handleGoBack} />

      <div className="tattoo-list mt-5 col-11 ml-3">
        {tattoos.map((tattoo) => (
          <div
            className="col-12 card card-with-shadow mb-4"
            onClick={() => showQrModal(tattoo)}
            key={tattoo._id}
          >
            <div className="row">
              <div className="col-2">
                <QRCode
                  value={`${thisUrl}/out-profile/${user.userslug}`}
                  size={80}
                  className="qr-code-canvas"
                />
              </div>
              <div className="col offset-2 mt-3">
                <h3>{tattoo.nickname}</h3>
                <p>Scans: {tattoo.scanCount}</p>
              </div>
              <div className="col mt-3 primary-color">
                <BiShare className="icon-md" />
              </div>
            </div>
          </div>
        ))}
      </div>

        <QrModal
          show={qrModalVisible}
          onHide={() => setQrModalVisible(false)}
          userSlug={`${thisUrl}/out-profile/${user.userslug}`}
        />
    </div>
  );
};

export default MobileShare;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ModalJoinEvent from '../modalJoinEvent';
import vCardsJS from 'vcards-js';
import download from 'downloadjs';
import { Transition } from 'react-transition-group'; // Import Transition
import '../../../../styles/sass/pages/connections.scss';
import { BiChevronLeft } from "react-icons/bi";
import { BsGear, BsFillPersonFill } from "react-icons/bs";
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ContentMobile from '../../../external/components/contentMobile';
import { Modal, Button } from 'react-bootstrap';
import BusinessCard from '../../../external/components/BusinessCard'; // Import the BusinessCard component
import TopNav from '../mobile/topNav';
import useUserData from '../../hooks/useUserData';

import api from '../../../../utils/api';
import { AiOutlineSearch, AiOutlineClose } from 'react-icons/ai'; // Import the search and close icons

const ContactItem = ({ user, openProfileModal }) => {
  const handleClick = () => {
    openProfileModal();
  };
  return (
    <div className="contact-card row col-11 ml-1" onClick={handleClick}>
      <div className="ml-1">
        <img src={user.profilePicture} className="rounded-circle profile-pic" alt={user.firstName} />
      </div>
      <div className="col">
        <h4>{user.firstName} {user.lastName}</h4>
        <p>Email: {user.email}</p>
        {/* Add other contact details you want to display */}
      </div>
    </div>
  );
};



const Contacts = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('@SKANZ/user')));
  const { qrCodeToJoin: businessId } = useParams();
  const [modalJoinEvent, setModalJoinEvent] = useState(false);
  const [codeToJoin, setCodeToJoin] = useState('');
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [groupedContacts, setGroupedContacts] = useState({});
  const [searchInput, setSearchInput] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [events, setEvents] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false); // State to track whether the search bar is open
  const history = useHistory();
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // State to store the selected user's information

  
  // Initialize filteredContacts with all contacts when the component mounts
  useEffect(() => {
    getContacts();
    getEvents();
    if (businessId) {
      getEvent();
    }
  }, []);

  const getContacts = async () => {
    try {
      const { data } = await api.get('/user/contacts/all');
      setContacts(data);

      // Initialize groupedContacts with all contacts
      groupContactsAlphabetically(data);
    } catch (error) {
      console.log(error);
    }
  };

  

  const getEvents = async () => {
    try {
      const { data } = await api.get('/event/user/events');
      setEvents(data.events.reverse());
    } catch (error) {
      console.log(error);
    }
  };

  const getEvent = async () => {
    try {
      const { data } = await api.get(`/event/business/${businessId}`);
      console.log(data.result[0]);
      setCodeToJoin(data.result[0].uniqueCode);
    } catch (error) {
      setCodeToJoin(businessId);
      setModalJoinEvent(true);
    }
  };

  const saveContactVCard = async (user, setIsDownloading) => {
    setIsDownloading(true);
    var vCard = vCardsJS();
    vCard.firstName = user?.firstName;
    vCard.lastName = user?.lastName;
    vCard.email = getEmail(user);
    user?.social?.forEach((social) => {
      vCard.socialUrls[social?.kind?.toString().toLowerCase()] = String(
        social?.data,
      );
    });
    vCard.cellPhone = getPhone(user);

    vCard.version = '3.0';

    const getEmail = (user) => {
      if (user?.contactEmails?.length > 0) {
        if (user?.contactEmails[0]) return user.contactEmails[0];
        return user?.email;
      }
      return user?.email;
    };

    const getPhone = (user) => {
      if (user?.contactPhones?.length > 0) {
        if (user?.contactPhones[0]) return user.contactPhones[0];
        return user?.phone;
      }
      return user?.phone;
    };

    download(
      vCard.getFormattedString(),
      `${user?.firstName}${user?.lastName}.vcf`,
      'text/x-vcard',
    );
    setIsDownloading(false);
    enqueueSnackbar('Contact saved successfully', {
      variant: 'success',
    });
  };

  // Function to group contacts alphabetically
  const groupContactsAlphabetically = (contacts) => {
    if (!contacts || contacts.length === 0) {
      // Handle the case where contacts are undefined or empty
      setGroupedContacts({});
      return;
    }

    const grouped = {};
    contacts.forEach((contact) => {
      const firstLetter = contact.user.firstName.charAt(0).toUpperCase();
      if (!grouped[firstLetter]) {
        grouped[firstLetter] = [contact];
      } else {
        grouped[firstLetter].push(contact);
      }
    });
    setGroupedContacts(grouped);
  };

  const filterContacts = (searchText) => {
    // Update searchInput
    setSearchInput(searchText);

    // Filter contacts based on searchText
    const filtered = searchText
      ? contacts.filter(
          (contact) =>
            contact.user.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
            contact.user.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
            contact.user.email.toLowerCase().includes(searchText.toLowerCase())
        )
      : contacts;

    // Update filteredContacts with the filtered result
    setFilteredContacts(filtered);

    // Group contacts alphabetically
    groupContactsAlphabetically(filtered);
  };

  // Function to open the profile modal and set the selected user's information
  const openProfileModal = (user) => {
    setSelectedUser(user);
    setProfileModalOpen(true);
  };


  // Function to close the profile modal
  const closeModal = () => {
    setProfileModalOpen(false);
  };


  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <div className="connections container">
  
        <TopNav title="Connections" goBack={handleGoBack} />

        <div className="connection-main">
          <div
            className="topbar align-items-center mb-3 mt-1"
            style={{
              transition: 'all 0.5s ease-in-out',
              overflow: 'hidden',
            }}
          >
            <div className="search-container col-10 offset-1 mb-4" style={{ height: '56px' }}>

              <input
                type="text"
                placeholder="Search..."
                value={searchInput}
                onChange={(e) => filterContacts(e.target.value)}
                className={`form-control search-input-open`}
              />
            </div>
          </div>

          {/* Check if there are no contacts and display the message */}
          {contacts.length === 0 ? (
            <div className="no-contacts-message">
              <h4>You don't have any contacts yet </h4>
                <h6> Scan your connection's SKANZ qr codes to build your contacts library!</h6>
            </div>
          ) : (
            Object.keys(groupedContacts).map((letter) => (
              <div key={letter} className="letter-group mb-5">
                <h1 className="ml-1">{letter}</h1>
                {groupedContacts[letter].map((contact) => (
                  <ContactItem
                    key={contact._id}
                    user={contact.user}
                    openProfileModal={() => openProfileModal(contact.user)}
                  />
                ))}
              </div>
            ))
          )}
        </div>
      </div>

      <Modal show={isProfileModalOpen} onHide={closeModal} className="modal">
        {/* Add the X button */}
        <button
          className={`clear-icon outline-button`}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            zIndex: '1000',
          }}
          onClick={closeModal}
        >
          <AiOutlineClose className="icon-sm search-icon" />
        </button>
        <Modal.Body>
          <BusinessCard user={selectedUser} profileType="Standard" /> {/* Pass the user and profileType */}
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Contacts;
import React from 'react';
import { AiOutlineMail, AiOutlinePhone, AiOutlineCalendar } from 'react-icons/ai';



const ContactIcons = ({ isEmailVisible, toggleEmailVisibility, email }) => {
    return (
        <div className="row offset-3 col-6 jd-flex justify-content-center text-center mb-4">
            {email ? (
                <a
                    href={`mailto:${email}`}
                    className={`primary-color contact-icon-container ${isEmailVisible ? 'visible' : 'hidden'}`}
                    onClick={toggleEmailVisibility}
                >
                    <AiOutlineMail className="icon-md" />
                </a>
            ) : (
                <div
                    className={`contact-icon-container ${isEmailVisible ? 'visible' : 'hidden'}`}
                    onClick={toggleEmailVisibility}
                >
                    <AiOutlineMail className="icon-md" />
                </div>
            )}

        {/*
        <div>
            <AiOutlinePhone className="icon-md" />
        </div>

        <div>
            <AiOutlineCalendar className="icon-md" />
        </div>
    */}
    </div>

    );

};

export default ContactIcons
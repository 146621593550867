import React, { useState } from 'react';
import { Table, Form } from 'react-bootstrap';
import { TbEdit } from "react-icons/tb";
import { AiOutlineEye } from "react-icons/ai";


const SocialLinks = ({ 
  socialData, 
  onEditClick, 
  onToggleVisibility,
  includeLink
}) => {
  const [error, setError] = useState('');

  // Apply the filter function to the socialData
  const filteredSocialData = socialData.filter(includeLink);

  const handleToggleVisibility = async (id) => {
    setError('');
    try {
      await onToggleVisibility(id);
    } catch (error) {
      console.error('Error toggling visibility:', error);
      setError('Failed to change visibility.');
    }
  };

  return (
    <>
      {error && <div className="text-danger">{error}</div>}
      <Table className="col-10 offset-1">
        <thead>
          <tr>
            <th className="col"><p> Name</p></th>
            <th className="col"></th>
            <th className="col text-center"><AiOutlineEye className="social-vis-icon" /></th>
          </tr>
        </thead>
        <tbody>
          {filteredSocialData.map((socialItem) => (
            <React.Fragment key={socialItem._id}>
              <tr className="bg-secondary-brand">
                <td className="text-left text-truncate">
                  {socialItem.nickname}
                </td>
                
                <td>
                  <TbEdit className="edit-icon" onClick={() => onEditClick(socialItem._id)} />
                </td>

                <td className="text-right">
                  <Form>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`customSwitch${socialItem._id}`}
                        checked={socialItem.eventVisible}
                        onChange={() => handleToggleVisibility(socialItem._id)}
                      />
                      <label className="custom-control-label" htmlFor={`customSwitch${socialItem._id}`}></label>
                    </div>
                  </Form>
                </td>
              </tr>
              <tr className="spacer"><td></td></tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default SocialLinks;
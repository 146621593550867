import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import api from '../../../../utils/api'; // Assuming the API utility is in the same path

// The AddLinkModal component is responsible for rendering a modal with a form.
const AddLinkModal = ({ show, handleClose }) => {
  // These states hold the values of the form fields.
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');

  // This function is called when the form is submitted.
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents the default form submit action.

    try {
      let user = JSON.parse(localStorage.getItem('@SKANZ/user')); // Retrieve user from local storage
      let formattedUrl = url;

      // Add protocol if not present
      if (!formattedUrl.startsWith('http://') && !formattedUrl.startsWith('https://')) {
        formattedUrl = `https://${formattedUrl}`;
      }

      // Update user social links
      user.social = [
        ...user.social,
        { kind: 'Url', data: formattedUrl, nickname: name, visible: true }, // Adjust structure as needed
      ];

      // Perform the API call to update the user data
      const { data } = await api.put('/user/info', user);

      // Update local storage with new user data
      localStorage.setItem('@SKANZ/user', JSON.stringify(data.user));

      // Redirect or update UI accordingly
      window.location.href = '/profile'; // or update state to reflect changes
    } catch (error) {
      console.error('Failed to update the link:', error);
    }

    handleClose(); // Calls the handleClose function passed as a prop to close the modal.
  };

  return (
    // The Modal component from react-bootstrap.
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title className="mt-2">Add New Link</Modal.Title>
        {/* This button uses an icon from react-icons for the close functionality. */}
        <Button variant="none" onClick={handleClose} className="close-btn">
          <AiOutlineClose size={24} />
        </Button>
      </Modal.Header>
      {/* Modal body contains the form */}
      <Modal.Body className="p-3">
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            {/* Input field for the 'name' */}
            <Form.Label>Link Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)} // Updates the state on change.
              required // Makes the field required.
            />
          </Form.Group>
          <Form.Group className="mt-4 mb-4">
            {/* Input field for the 'url' */}
            <Form.Label>URL</Form.Label>
            <Form.Control
              type="url"
              placeholder="Enter URL"
              value={url}
              onChange={(e) => setUrl(e.target.value)} // Updates the state on change.
              required // Makes the field required.
            />
          </Form.Group>
          {/* Submit button for the form */}
          <Button variant="primary" type="submit" className="btn btn-large">
            Save
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddLinkModal;

import { useState, useEffect } from 'react';
import api from '../../../utils/api';

const useUserData = () => {
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('@SKANZ/user')));

  const persistUserData = (data) => {
    localStorage.setItem('@SKANZ/user', JSON.stringify(data));
  };

  // New function to handle any changes in social links array
  const updateSocialLinks = async (updatedSocialData) => {
    try {
      const res = await api.put('/user/info', { social: updatedSocialData });
      if (res.data.user) {
        setUserData(res.data.user);
        persistUserData(res.data.user);
      }
      return res.data.user;
    } catch (error) {
      console.error('Error updating user info:', error);
      throw error; // Ensure to throw the error so that it can be caught by the calling function
    }
  };

  const addSocialLink = async (newLink) => {
    const updatedSocialData = [...userData.social, newLink];
    await updateSocialLinks(updatedSocialData);
  };

  const updateLink = async (id, updatedLink) => {
    const updatedSocialData = userData.social.map((item) =>
      item._id === id ? updatedLink : item
    );
    await updateSocialLinks(updatedSocialData);
  };

  const removeLink = async (idToRemove) => {
    const updatedSocialData = userData.social.filter((item) => item._id !== idToRemove);
    await updateSocialLinks(updatedSocialData);
  };

  const toggleSocialLinkVisibility = async (id) => {
    const updatedSocialData = userData.social.map((item) =>
      item._id === id ? { ...item, eventVisible: !item.eventVisible } : item
    );
    await updateSocialLinks(updatedSocialData);
  };

  //change position
  const updatePosition = async (position) => {
    try {
      const res = await api.put('/user/info', { position: position });
      if (res.data.user) {
        setUserData(res.data.user);
        persistUserData(res.data.user);
      }
      return res.data.user;
    } catch (error) {
      console.error('Error updating user info:', error);
      throw error;
    }
  };

  // Determine initial state of email visibility from userData
  const initialEmailVisibility = userData?.social?.find(
    (link) => link.nickname === "Email"
  )?.eventVisible;

  const [isEmailVisible, setIsEmailVisible] = useState(initialEmailVisibility);

  // Method to toggle email visibility
  const toggleEmailVisibility = async () => {
    const emailLinkIndex = userData.social.findIndex(
      (link) => link.nickname === "Email"
    );

    if (emailLinkIndex > -1) {
      const updatedLink = {
        ...userData.social[emailLinkIndex],
        eventVisible: !userData.social[emailLinkIndex].eventVisible,
      };
      await updateLink(userData.social[emailLinkIndex]._id, updatedLink);
      setIsEmailVisible(updatedLink.eventVisible);
    }
  };

  // New useEffect to watch for changes in userData and update isEmailVisible state accordingly
  useEffect(() => {
    const currentEmailVisibility = userData?.social?.find(
      (link) => link.nickname === "Email"
    )?.eventVisible;

    if (currentEmailVisibility !== undefined) {
      setIsEmailVisible(currentEmailVisibility);
    }
  }, [userData]);


  return {
    userData,
    addSocialLink,
    updateLink,
    removeLink,
    toggleSocialLinkVisibility,
    isEmailVisible,
    toggleEmailVisibility,
    updatePosition
  };
};



export default useUserData;

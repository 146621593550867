import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { saveContact } from '../../../utils/User'; // Import the saveContact function
import skanzlogoname from '../../../images/skanzlogoname.png';

const ContactActionsButtons = ({ user, event, isLoading, setIsLoading, enqueueSnackbar }) => {
  const saveOnSkanz = () => {
    const localUser = localStorage.getItem('@SKANZ/user');
    localStorage.setItem(
      '@SKANZ/unloggedcontact',
      JSON.stringify({ user: user, event: event?._id }),
    );
    if (!localUser) {
      window.open('/alert', '_blank').focus();
    } else {
      window.open('/profile', '_blank').focus();
    }
  };

  const downloadContact = async () => {
    setIsLoading(true);
    await saveContact(user); // Use the imported saveContact function
    setIsLoading(false);
    enqueueSnackbar('Contact saved successfully', {
      variant: 'success',
    });
  };

  return (
    <div className="fixed-bottom bg-secondary-brand bottom-bar-shadow">
      {/* Save to SKANZ Button */}
      <div className="mt-4">
        <div className="row d-flex justify-content-between col-10 offset-1">
          <button
            type="button"
            className="btn-biz-card"
            onClick={saveOnSkanz}
          >
            Save to SKANZ
          </button>

          {/* Download Contact Button */}

          <button
            className="btn-biz-card"
            onClick={downloadContact}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Add to Contacts'}
          </button>

        </div>

      {/* Skanz Logo */}
      <div className="mb-2 text-center mt-3">
        <img src={skanzlogoname} alt="Skanz Logo Name" className="col-4" />
      </div>
    </div>
    </div>
  );
};

export default ContactActionsButtons;

import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useStore } from '../../../store/user';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import api from '../../../utils/api';

import '../../../styles/sass/pages/connectCard.scss';

import ContactActionsButtons from './ContactActionsButtons';
import BusinessCard from './BusinessCard'; // Import the BusinessCard component

const ContentMobile = () => {
  const { slug } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const eventId = new URLSearchParams(location.search).get('e');
  const [user, setUser] = useState();
  const [event, setEvent] = useState(null);
  const [profileType, setProfileType] = useState(null); // Define profileType here

  const getUserProfile = async () => {
    try {
      const { data } = await api.get(`/user/slug/${slug}`);
      setUser(data.user);
      setProfileType(data.user.profileType); // Set profileType from user data
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const getEvent = async () => {
    try {
      const { data } = await api.get(`/event/${eventId}`);
      setEvent(data.event);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserProfile();
    if (eventId) getEvent();
  }, []);

  if (!user) {
    return <></>;
  }

  return (
    <>
      {profileType === 'Standard' && (
        <>
          <BusinessCard user={user} profileType={profileType} /> {/* Pass profileType as a prop */}
          
            <ContactActionsButtons
              user={user}
              event={event}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              enqueueSnackbar={enqueueSnackbar}
            />
        </>
      )}
    </>
  );
};

export default ContentMobile;

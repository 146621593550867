import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';

const Home = () => {
  return (
    <>
      <div className="home-page">
        <Container className="text-center">
            <Col xs={12}>
              <img src="/assets/img/brand/skanzLogo.png" alt="Skanz Logo" className="skanz-home-logo mt-4 mb-4 img-fluid" />
              <h4>Your all-in-one modern networking tool</h4>
            </Col>

          
            <Col>
              <img 
                src="/assets/img/landing/person-wearing-orange-in-wind.png" 
                alt="Person Wearing Orange" 
                className="mt-3 mb-1 img-fluid skanz-hero-img"
              />
              <h5 className="mt-5 mb-1">Build your digital business card and expand your network today!</h5>
            </Col>

            <div className="fixed-bottom mb-2">
              <Col>
                <Link to="/sign-up" className="btn btn-large mt-2">
                  Sign Up
                </Link>
              </Col>


              <Col>
                <Link to="/login" className="btn transparent-btn-large mt-2">
                  Log In
                </Link>
              </Col>
            </div>
        </Container>
      </div>
    </>
  );
};

export default Home;

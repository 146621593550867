import React from 'react';
import { AiOutlineMail, AiOutlinePhone, AiOutlineGlobal } from 'react-icons/ai';
import { Table } from 'react-bootstrap';

import ContactIcons from '../../user/components/mobile/contactIcons'
import useUserData from '../../user/hooks/useUserData';
import SkanzBg from '../../../images/skanz-bg.png';


const BusinessCard = ({ user }) => {
  // Check if the social item with the nickname "Email" is visible
  const isEmailVisible = user.social.some(
    (socialItem) => socialItem.nickname === 'Email' && socialItem.eventVisible
  );

  const getDomainFromURL = (url) => {
    try {
      const link = new URL(url);
      return link.hostname.replace('www.', '');
    } catch (error) {
      return '';
    }
  };

  return (
    <>
      <div className="profile-page">
        <div className="justify-content-center d-flex">
          <div className="card col-12 profile-card">
            <div className="user-background-crop">
              <img
                className="card-img-top"
                alt="user background image"
                src={SkanzBg}
              />
            </div>
            <img
              className="rounded-circle col-4 profile-picture"
              alt="skanz profile picture"
              src={user?.profilePicture ?? '/assets/img/newUser.png'}
            />
            <h1 className="text-center user-name mt-7">
              {`${user.firstName} ${user.lastName}`}
            </h1>
            <p className="text-center mb-3">{user.position}</p>
            <ContactIcons 
              isEmailVisible={isEmailVisible}
              email={user.email} 
            />

            {/* Social Links Section */}

          </div>
          
        </div>
        <div className="row offset-1 col-10 jd-flex justify-content-between text-center link-section-offset">
              <Table bordered>
                <thead>
                  <tr>
                  </tr>
                </thead>
                <tbody>
                  {user.social.map((socialItem, index) => {
                    // Ignore social items with nickname "Email"
                    if (socialItem.nickname === 'Email') return null;

                    // Check if eventVisible is true before displaying the link
                    if (socialItem.eventVisible) {
                      const displayName = socialItem.nickname || getDomainFromURL(socialItem.data);

                      const link = new URL(socialItem.data);
                      const domain = link.hostname;
                      
                      return (
                        <tr key={index}>
                          <div className='link-item'>
                            <a href={socialItem.data} target="_blank" rel="noopener noreferrer">
                              <td className="text-left text-white">
                                <h5>{displayName}</h5>
                              </td>
                            </a>
                          </div>
                        </tr>
                      );
                    }
                    return null;
                  })}
                </tbody>
              </Table>
            </div>
      </div>
    </>
  );
};

export default BusinessCard;

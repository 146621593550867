
import React, { useEffect, useState } from 'react';
import api from '../../../../utils/api';
import { Card, Container, Row, Col } from 'react-bootstrap';
import TopNav from '../mobile/topNav';
import { useHistory } from 'react-router-dom';
import '../../../../styles/sass/pages/events.scss';

{/* This Component is not being used! */}

const EventPage = () => {
  const [events, setEvents] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const getEvents = async () => {
      try {
        const { data } = await api.get('/event/user/events');
        setEvents(data.events.reverse());
      } catch (error) {
        console.log(error);
      }
    };

    getEvents();
  }, []);
  
  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className="events">
      <TopNav title="Connections" goBack={handleGoBack} />
      <h2 className="mt-5 ml-4">Your Events</h2>

      <Container>
        <Row>
          {events.map((event) => (
            <Col key={event._id} xs={12} sm={6} md={4} lg={3}>
                <Card className="event-card" 
                style={{
                     backgroundImage: `url(${event.logoUrl})`,
                     backgroundPosition: 'center'
                     }}>

                <div className="bg-overlay">
                    <Card.Body>
                        <h1 className="text-white">{event.name}</h1>
                        <h3 className="text-white">
                            {new Date(event.time.start).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                            </h3>
                    </Card.Body>
                </div>
                </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default EventPage;

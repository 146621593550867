import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Table, Form, Button } from 'react-bootstrap';
import { BiChevronLeft } from "react-icons/bi";
import { TbEdit } from "react-icons/tb";

import EditUserProfile from './editProfile';
import SocialLinks from './mobileSocialLinks';
import TopNav from '../mobile/topNav';
import BottomNavBar from '../mobile/bottomNavBar';
import AddLinkModal from './mobileAddLink';
import EditLinkModal from './mobileEditLink';
import ContactIcons from '../mobile/contactIcons';
import PictureEditor from './pictureEditor';
import useUserData from '../../hooks/useUserData';
import { useStore } from '../../../../store/user';
import ChangePositionModal from './changePositionModal';
import SkanzBg from '../../../../images/skanz-bg.png';

import '../../../../styles/sass/pages/profile.scss';

const MobileProfile = () => {
  const history = useHistory();
  const [isEditing, setIsEditing] = useState(false);
  const { user, setUser } = useStore((state) => state);


  const {
    userData,
    updateUserSocialData,
    addSocialLink,
    updateLink,
    removeLink,
    toggleSocialLinkVisibility,
    isEmailVisible, 
    toggleEmailVisibility,
  } = useUserData();

  const [showEditModal, setShowEditModal] = useState(false);
  const [editingLinkId, setEditingLinkId] = useState(null);
  const [showPositionModal, setShowPositionModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleEditClick = () => setIsEditing(true);
  const handleGoBack = () => history.goBack();
  const handleCloseModal = () => setShowModal(false);
  const handleCloseEditModal = () => setShowEditModal(false);
  const handlePositionClick = () => setShowPositionModal(true);

  const handleEditLinkClick = (index) => {
    setEditingLinkId(index);
    setShowEditModal(true);
  };

  const handleProfileUpdate = (updatedUserData) => {
    setUser(updatedUserData); // Update the global user state
  };

  

  const isSpecializedLink = link => link?.data?.startsWith('mailto:') || link?.data?.startsWith('tel:');
  const isNotSpecializedLink = link => !isSpecializedLink(link);



  return (
    <>
      {isEditing ? (
        <EditUserProfile onCancel={() => setIsEditing(false)} />
      ) : (
        <div className="profile-page">
          <TopNav title="Profile" goBack={handleGoBack} />
          <div className="justify-content-center d-flex mt-2">
            <div className="card col-11 profile-card">
              <div className="user-background-crop">
                <img
                  className="card-img-top"
                  alt="user background image"
                  src={SkanzBg}
                />
              </div>
              <PictureEditor
                type="profile"
                setUser={setUser}
                picture={user?.profilePicture}
                onUploadSuccess={handleProfileUpdate}
              />

              <h1 className="text-center user-name mt-6">
                {`${userData.firstName} ${userData.lastName}`}
              </h1>
              {/* Conditionally display "Add Subtitle" or the position */}
              <p className="text-center position-text mb-3" onClick={handlePositionClick}>
                {userData.position ? userData.position : 'Add Subtitle'} <TbEdit className="primary-color position-edit-icon" />
              </p>              
              <ContactIcons 
                isEmailVisible={isEmailVisible} 
                toggleEmailVisibility={toggleEmailVisibility}
              />
              <div className="text-center mb-4">
                <a className="btn btn-outline-primary" href={`/out-profile/${userData.userslug}`} target="_blank" rel="noreferrer">Preview</a>
              </div>
            </div>
          </div>
          <h2 className="text-center">Contact Info</h2>
          <SocialLinks socialData={userData.social} onEditClick={handleEditLinkClick} onToggleVisibility={toggleSocialLinkVisibility} includeLink={isSpecializedLink} />
          <h2 className="text-center">Custom Links</h2>
          <SocialLinks 
            socialData={userData.social} 
            onEditClick={handleEditLinkClick} 
            onToggleVisibility={toggleSocialLinkVisibility} 
            includeLink={isNotSpecializedLink} 
            />
          <div className="addlink-modal-btn">
            <button type="button" className="btn transparent-btn-large col-10 offset-1" onClick={() => setShowModal(true)}>
              Add Link +
            </button>
          </div>

          <BottomNavBar />
          {showModal && <AddLinkModal show={showModal} handleClose={handleCloseModal} />}
          {showEditModal && (
            <EditLinkModal show={showEditModal} handleClose={handleCloseEditModal} handleUpdateLink={updateLink} handleRemoveLink={() => removeLink(editingLinkId)} link={userData.social.find((item) => item._id === editingLinkId)} />
          )}

          {showPositionModal && (
            <ChangePositionModal
              show={showPositionModal}
              handleClose={() => setShowPositionModal(false)}
              user={userData}
            />
          )}
        </div>
      )}
    </>
  );
};

export default MobileProfile;

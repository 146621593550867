import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { BiHomeAlt2 } from "react-icons/bi";
import { IoPersonOutline } from "react-icons/io5";
import { AiOutlineQrcode, AiOutlineCalendar } from "react-icons/ai";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { NavLink, useLocation } from 'react-router-dom'; // Import NavLink and useLocation from react-router-dom
import QrModal from '../mobile/QrModal';
import api from '../../../../utils/api';
import '../../../../styles/sass/pages/bottomNavBar.scss';



const BottomNavBar = () => {

  const [tattoos, setTattoos] = useState([]);
  const user = JSON.parse(localStorage.getItem('@SKANZ/user'));
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const [selectedTattoo, setSelectedTattoo] = useState(null);
  const thisUrl = window.location.origin;

  const showQrModal = () => {
    // Construct the URL for the QR code
    const qrCodeUrl = `${thisUrl}/out-profile/${user.userslug}`;
    setSelectedTattoo(qrCodeUrl); 
    setQrModalVisible(true);
  };
  
  useEffect(() => {
    // Fetch user's tattoos from the API
    const fetchTattoos = async () => {
      try {
        const response = await api.get(`/user/tattoos`);
        setTattoos(response.data.tattoos);
      } catch (error) {
        console.error('Error fetching tattoos:', error);
      }
    };

    fetchTattoos();
  }, [user.userslug]);

  const location = useLocation(); // Get the current location using useLocation()

  return (
    <Container fluid className="fixed-bottom bg-primary-brand bottom-bar-shadow">
      <Row>
        <Col>
          <Nav className="justify-content-around">
            <Nav.Item>
              <NavLink exact to="/dashboard" className={`nav-link ${location.pathname === '/dashboard' ? 'primary-color' : ''}`}>
                <BiHomeAlt2 />
                <div>Home</div>
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/profile" className={`nav-link ${location.pathname === '/profile' ? 'primary-color' : ''}`}>
                <IoPersonOutline />
                <div>Profile</div>
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <div
                className={`nav-link ${location.pathname === '/share' ? 'primary-color' : ''}`}
                onClick={showQrModal}>
                <AiOutlineQrcode />
                <div>Share</div>
              </div>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/contacts" className={`nav-link ${location.pathname === '/contacts' ? 'primary-color' : ''}`}>
                <HiOutlineUserGroup />
                <div>People</div>
              </NavLink>
            </Nav.Item>
            {/*<Nav.Item>
              <NavLink to="/events" className={`nav-link ${location.pathname === '/events' ? 'primary-color' : ''}`}>
                <AiOutlineCalendar />
                <div>Events</div>
              </NavLink>
            </Nav.Item>*/}
            
          </Nav>
        </Col>
      </Row>
      <QrModal
          show={qrModalVisible}
          onHide={() => setQrModalVisible(false)}
          userSlug={`${thisUrl}/out-profile/${user.userslug}`}
        />
    </Container>

    
  );
};

export default BottomNavBar;

import React, { useState } from 'react';
import api from '../../../../utils/api';
import { BiChevronLeft } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

const ChangeEmailForm = ({ onGoBack }) => {
  const history = useHistory();
  const storedUser = JSON.parse(localStorage.getItem('@SKANZ/user'));

  // Initialize newEmail with the current email from localStorage
  const [newEmail, setNewEmail] = useState(storedUser?.email || '');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const submitChangeEmail = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      // Verify the user's password by making a sign-in API call
      await api.post('/auth/signIn', {
        email: storedUser.email,
        password,
      });

      // If the password is correct, update the email
      await api.put('/user/info', { email: newEmail });

      // Email successfully changed
      setSuccess('Email successfully changed.');

      // Update the email in localStorage
      storedUser.email = newEmail;
      localStorage.setItem('@SKANZ/user', JSON.stringify(storedUser));

      // Redirect to the settings page
      history.push('/settings');
    } catch (error) {
      console.log(error);
      if (error.response?.data?.error) setError(error.response.data.error);
    }
  };

  return (
    <div>
      <button type="button" className="clear-icon" onClick={onGoBack}>
        <h2 className="mb-5 text-center ml-5">
          <BiChevronLeft className="icon-md mb-2" />
          <span className="mt-5">Change Email</span>
        </h2>
      </button>
      {error && <div className="alert alert-danger text-center">{error}</div>}
      {success && <div className="alert alert-success text-center">{success}</div>}
      <form onSubmit={submitChangeEmail} className="mt-3">
        <div className="form-group">
          <label className="form-control-label">New Email</label>
          <input
            required
            className="light-input form-control"
            type="email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label className="form-control-label">Password</label>
          <input
            required
            className="light-input form-control"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="mt-3 text-right">
          <button type="submit" className="btn btn-large btn-warning ml-2">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangeEmailForm;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IoMdHome } from "react-icons/io";
import { BsGear } from "react-icons/bs";
import { Link } from 'react-router-dom';

import { Card, Container, Row, Col, Nav } from 'react-bootstrap';
import '../../../../styles/sass/pages/dashboard.scss';

import BottomNavBar from '../mobile/bottomNavBar'; 
import '../../../../styles/sass/utility/utilities.scss';
import skanzlogoname from '../../../../images/skanzlogoname.png';
import api from '../../../../utils/api';

const user = JSON.parse(localStorage.getItem('@SKANZ/user'));

const MobileDashboard = () => {
  const [events, setEvents] = useState([]); // Ensure this is inside the component

  useEffect(() => {
    const getEvents = async () => {
      try {
        const { data } = await api.get('/event/user/events');
        setEvents(data.events.reverse());
      } catch (error) {
        console.log(error);
      }
    };

    getEvents();
  }, []);

  return (
    <>
      {/* The top part */}
      <div className="row col-12 justify-content-between mt-3">
        <img src={skanzlogoname} alt="Skanz Logo Name" className="col-4" />
        <Link to="/settings" className="text-white">
          <BsGear className="icon-size" />
        </Link>
      </div>

      <div className="justify-content-center d-flex mt-5">
          <img
            className="rounded-circle  gen-pfp"
            alt="placeholder"
            src={user?.profilePicture ?? '/assets/img/newUser.png'}
     
          />
      </div>

      <h1 style={{ textAlign: 'center' }}>
        {`${user.firstName} ${user.lastName}`}
      </h1>
      <div className="container ">
        <div className="
          row 
          d-flex 
          justify-content-center 
          text-white 
          text-center
          font-weight-bolder
        ">

            <span className="mr-1 primary-color">
              Scans:  
            </span> 
            
            <span>
             {`${user.profileViews}`}
            </span>
          </div>
        </div>

    {/* Events are displayed if the user has events */}
    {events.length > 0 ? (
      <>
    <h2 className="mt-5 ml-4">Your Events</h2>
      <Container>
        <Row>
          {events.map((event) => (
            <Col key={event._id} xs={12} sm={6} md={4} lg={3}>
                <Card className="event-card" 
                style={{
                     backgroundImage: `url(${event.logoUrl})`,
                     backgroundPosition: 'center'
                     }}>

                <div className="bg-overlay">
                    <Card.Body>
                        <h1 className="text-white">{event.name}</h1>
                        <h3 className="text-white">
                            {new Date(event.time.start).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                            </h3>
                    </Card.Body>
                </div>
                </Card>
            </Col>
          ))}
        </Row>
      </Container>
      </>
        ) : (
          <div className="no-events-message">
            {/* Display a message or other content when there are no events */}
            {/*<h3>No Events Found</h3>*/}
          </div>
        )}
      <BottomNavBar />
    </>
  );
   
};


export default MobileDashboard;

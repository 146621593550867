import React, { useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { AiOutlineClose, AiFillDelete } from 'react-icons/ai';

const EditLinkModal = ({ show, handleClose, handleUpdateLink, handleRemoveLink, link }) => {
    const [formData, setFormData] = useState({
        name: link?.nickname || '',
        url: link?.data || '',
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        const updatedLink = {
            ...link,
            nickname: formData.name,
            data: formData.url,
        };

        try {
            await handleUpdateLink(link._id, updatedLink);
            handleClose();
        } catch (error) {
            console.error(error);
            setError('Failed to update the link.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleRemove = async () => {
        setIsRemoving(true);
        try {
            await handleRemoveLink(link._id);
            handleClose();
        } catch (error) {
            console.error(error);
            setError('Failed to remove the link.');
        } finally {
            setIsRemoving(false);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header>
                <Modal.Title>Edit Link</Modal.Title>
                <Button variant="none" onClick={handleClose} className="close-btn">
                    <AiOutlineClose size={24} />
                </Button>
            </Modal.Header>
            <Modal.Body className="p-3">
                <Button variant="link" onClick={handleRemove} className="text-danger remove-link-btn ml-5" disabled={isRemoving}>
                    {isRemoving ? (
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    ) : (
                        <AiFillDelete className="mb-1" />
                    )}
                    <span>{isRemoving ? ' Removing...' : ' Remove Link'}</span>
                </Button>
                {error && <p className="text-danger">{error}</p>}
                <Form onSubmit={handleEditSubmit}>
                    <Form.Group>
                        <Form.Label>Link Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mt-4 mb-4">
                        <Form.Label>URL</Form.Label>
                        <Form.Control
                            type="url"
                            name="url"
                            value={formData.url}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="btn btn-large" disabled={isLoading}>
                        {isLoading ? 'Updating...' : 'Update Link'}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditLinkModal;

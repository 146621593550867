import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import api from '../../../../utils/api'; // Assuming the API utility is in the same path

const ChangePositionModal = ({ show, handleClose, user }) => {
  const [position, setPosition] = useState(user.position || ''); // Initialize with current position

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Update user position
      user.position = position;

      // Perform the API call to update the user data
      const { data } = await api.put('/user/info', user);

      // Update local storage with new user data
      localStorage.setItem('@SKANZ/user', JSON.stringify(data.user));

      // Redirect or update UI accordingly
      // (e.g., reload the page, update state, show success message, etc.)
      // window.location.reload(); // or other UI update logic
    } catch (error) {
      console.error('Failed to update the position:', error);
      // Show error message to the user
    }

    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title className="mt-2">Change Position</Modal.Title>
        <Button variant="none" onClick={handleClose} className="close-btn">
          <AiOutlineClose size={24} />
        </Button>
      </Modal.Header>
      <Modal.Body className="p-3">
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Position</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your new position"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="btn btn-large mt-3">
            Save
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePositionModal;
